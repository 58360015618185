
import { IProjects } from "../interfaces/IProjects";




export const projects: IProjects[] = [
{
    image: "",
    link: "https://spotify-summary.netlify.app/",

    title: "Spotify Top Track Generator",
    Description: "Spotify-Summary (I know, catchy name! :P) is a simple Spotify stat tracker that I developed using React. This project serves as a tool to analyze and visualize various statistics related to Spotify usage. I delved deep into how the API functions, allowing me to fetch data such as listening history and top tracks. This not only enhanced my technical skills in API integration but also gave me valuable insights into handling and displaying large sets of data efficiently within a React application. "
},
  ];






 


